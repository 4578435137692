import React from 'react'
import Link from 'gatsby-link'
import Header from '../components/Header'
import Contacts from '../components/Contacts'
import Services from '../components/Services'
import SS from '../components/Services/ss'
import { Grid, Row, Col } from 'react-flexbox-grid'
import Helmet from 'react-helmet'

const VVSPage = () => (
  <div>
    <Helmet
    title="Verslo sistemų programavimas"
    />
    <Header content={<div><h1>Verslo sistemų programavimas</h1><hr /><h2>Verslo procesų optimizavimui</h2></div>} />
      <div className="holder" css={{padding: '50px 0'}}>
          <Grid fluid>
            <Row>
              <Col xs={12} lg={7}>
              <section className="service">
                <h1>Verslo sistemų programavimas</h1>
                <p>Įsteigus įmonę, dažniausiai užtenka nedidelio kiekio darbuotojų ir paprastų darbinių priemonių.
                Smulkiam verslui išaugus į rimtesnę įmonę, turinčią daugiau darbuotojų ir vykdančią platesnę veiklą, 
                susiduriama su nauju iššūkiu – verslo procesų optimizavimu. Daugybė veiksmų, kuriuos atliekate, 
                gali būti automatizuoti, taupant jūsų darbuotojų laiką. Duomenys, kuriuos kaupiate, 
                gali tapti galingu įrankiu, leidžiančiu priimti geresnius sprendimus. 
                Tam tereikia tik tinkamos, būtent jums sukurtos verslo valdymo sistemos.</p>
                <p>Mes kuriame sistemas pagal individualius kiekvienos įmonės poreikius ir darbo specifiką, 
                  todėl darbą pradedame nuo konsultacijos ir poreikių analizės. 
                  Tačiau kol svarstote, ar mūsų paslaugos jums apskritai reikalingos, norime pristatyti savo kuriamų sistemų galimybes.
                </p>

                
                <p>
                <h2>Įmonės procesų skaitmenizavimas</h2>
                Įmonėje naudojamus XLS failus paverčiame patogiomis duomenų surinkimo formomis, kurios teikia daug platesnes galimybes nei įprastos lentelės. 
                Viskas, kas vyksta jūsų įmonėje gali būti paversta duomenimis, o sistema padės lengvai paversti duomenis naudinga informacija.
                Sistema bus paprasta naudotis, tad naujiems darbuotojams nereikės specifinių IT įgūdžių ar sudėtingų mokymų.
                </p>

                <p>
                <h2>Duomenų sujungimas</h2>
                Šiandien kone kiekvienai problemai spręsti ar tikslui siekti naudojame po atskirą IT įrankį ar kanalą. Mūsų sistemos leidžia sujungti duomenis tarp visų jūsų jau naudojamų informacinių sistemų (pvz., marketingo, CRM, apskaitos sistemų). Sukursime jums patogų darbinį centrą, iš kurio galėsite valdyti visas kitas sistemas ir jų kaupiamus duomenis.
                </p>

                <p>
                <h2>Istorinių duomenų analizė</h2>
    Kuo ilgiau dirbate, tuo daugiau su savo veikla susijusių duomenų sukaupiate. Sistema gali ne tik kaupti įvairius duomenis, bet ir paversti juos prognozėmis, įžvalgomis, naudinga statistika ir t. t. Duomenų analizė gali būti pateikiama įvairiomis, jums priimtiniausiomis formomis (XLS formatu, įvairiais grafikais).

                </p>

                <p>
                <h2>Procesų planavimas</h2>
Bet kokios įmonės veikla susideda iš besikartojančių procesų, kuriems galima pritaikyti pardavimų piltuvėlio schemą, išskaidant pardavimo ar gamybos procesus į konkrečius etapus ir užduotis, kurias reikia atlikti. Mes padėsime jums išsigryninti konkrečius procesus ir jų etapus, o sukurta sistema padės efektyviau planuoti darbus ir valdyti procesų eigą.
                </p>

                <p>
                <h2>Pagalba priimant sprendimus</h2>
Galime sukurti sistemą, kuri analizuodama įvairius pateiktus duomenis (kainoraščius, istorinius duomenis ir kt.) jūsų darbuotojams padės priimti geresnius sprendimus per trumpesnį laiką. Darbuotojui gali būti pateikti duomenų analize paremti atsakymai, kiek gali kainuoti vienas ar kitas sprendimas, kokia bus proceso trukmė, koks rizikos laipsnis, pelningumas ir pan. 
                </p>

                <p>
                <h2>Procesų stebėsena</h2>
Galime į jūsų sistemą įdiegti automatinį „sargą“, kuris informuos atsakingus asmenis kiekvieną kartą, kai tik įvyks koks nors nestandartinis veiksmas. Sutaupysite daug laiko ir galėsite laiku užbėgti už akių galimiems nesklandumams, nes nebereikės rankiniu būdu tikrinti, ar procesai vyksta sklandžiai ir be nuokrypių.
                </p>
                <p>
                Kiek ir kokių funkcijų reikia jums, priklauso nuo jūsų verslo specifikos. Mūsų komanda pasiruošusi išanalizuoti jūsų įmonės poreikius ir pasiūlyti tinkamiausią verslo sistemos variantą.

                </p>
              </section>
              </Col>
              <Col xs={12} lg={5}>
                <div className="insideBlock">
                  <SS />
                </div>
              </Col>
            </Row>
          </Grid>
        
    </div>
    <Contacts />
  </div>
)

export default VVSPage

